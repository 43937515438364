import React from "react";
import Dropdown from "./Dropdown";
import useCustomDispatch from "../utils/useCustomDispatch";
import { formatKeys } from "../utils/utils";

const Asset_Filters = ({
  selectedCategory,
  selectedPlatform,
  selectedType,
  selectedStatus,
  selectedPerformance,
  selectedSortBy,
  categories,
  platforms,
  types,
  Status,
  performance,
  sortBy,
  getShadeInfo,
  categoryCounts = {},
  platformCounts = {},
  typeCounts = {},
  statusCounts = {},
  performanceCounts = {},
  totalCounts = {},
  disabledPlatforms,
}) => {
  
  const {
    handleCategoryChange,
    handlePlatformsChange,
    handleTypesChange,
    handleStatusChange,
    handlePerformanceChange,
    handleSortByChange,
  } = useCustomDispatch();

  const renderPerformanceLabel = (shade) => {
    const shadeInfo = getShadeInfo(shade);
    return (
      <>
        <span
          className="shade-circle"
          style={{ backgroundColor: shadeInfo.color }}
        ></span>
        {`${formatKeys(getShadeInfo(shade).label)} (${
          performanceCounts[getShadeInfo(shade).label] || 0
        }/${totalCounts[getShadeInfo(shade).label] || 0})`}
      </>
    );
  };

  return (
    <div className="d-flex flex-wrap mt-2">
      <Dropdown
        id="categoryDropdown"
        input_type="checkbox"
        label="Categories"
        items={categories}
        selectedItems={selectedCategory}
        handleChange={handleCategoryChange}
        counts={categoryCounts}
        totalCounts={totalCounts}
      />
      <Dropdown
        id="platformDropdown"
        input_type="checkbox"
        label="Platforms"
        items={platforms}
        selectedItems={selectedPlatform}
        handleChange={handlePlatformsChange}
        counts={platformCounts}
        totalCounts={totalCounts}
        isDisabled={(platform) => disabledPlatforms.includes(platform)}
      />
      <Dropdown
        id="typeDropdown"
        input_type="checkbox"
        label="Types"
        items={types}
        selectedItems={selectedType}
        handleChange={handleTypesChange}
        counts={typeCounts}
        totalCounts={totalCounts}
      />
      <Dropdown
        id="statusDropdown"
        input_type="checkbox"
        label="Status"
        items={Status}
        selectedItems={selectedStatus}
        handleChange={handleStatusChange}
        counts={statusCounts}
        totalCounts={totalCounts}
      />
      <Dropdown
        id="performanceDropdown"
        input_type="checkbox"
        label="Performance"
        items={performance}
        selectedItems={selectedPerformance}
        handleChange={handlePerformanceChange}
        renderCustomLabel={(shade) => renderPerformanceLabel(shade)}
      />
      <Dropdown
        id="sortByDropdown"
        input_type="radio"
        label="Sort By"
        items={sortBy}
        selectedItems={[selectedSortBy]}
        handleChange={handleSortByChange}
        renderCustomLabel={(sortby) => formatKeys(sortby)}
      />
    </div>
  );
};

export default Asset_Filters;
