import React from "react";
import { formatKeys } from "../utils/utils";

const Dropdown = ({
  id,
  input_type,
  label,
  items,
  selectedItems,
  handleChange,
  counts,
  totalCounts,
  isDisabled = () => false,
  renderCustomLabel = (item) => formatKeys(item),
}) => {
  // Handle Select All functionality
  const handleSelectAll = () => {
    items.forEach((item) => {
      if (!selectedItems.includes(item) && !isDisabled(item)) {
        handleChange(item);
      }
    });
  };

  // Handle Clear All functionality
  const handleClearAll = () => {
    selectedItems.forEach((item) => handleChange(item));
  };

  // Check if all items are selected
  const isAllSelected =
    items.every((item) => selectedItems.includes(item) || isDisabled(item)) &&
    selectedItems.length > 0;

  // Check if none of the items are selected
  const isNoneSelected = selectedItems.length === 0;

  return (
    <div className="dropdown mx-1 mt-1">
      {/* Dropdown button */}
      <button
        className="btn btn-black dropdown-toggle text-white border-secondary py-1"
        type="button"
        id={id}
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {label}
      </button>
      <ul className="dropdown-menu bg-black text-white" aria-labelledby={id}>
        {input_type === "checkbox" && (
          <li className="d-flex justify-content-end align-items-center pe-2">
            <button
              className={`btn btn-link text-decoration-none p-0 mx-2 ${
                isAllSelected ? "text-light" : "text-white"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                if (!isAllSelected) handleSelectAll();
              }}
              disabled={isAllSelected}
            >
              Select all
            </button>
            <button
              className={`btn btn-link text-decoration-none p-0 mx-2 ${
                isNoneSelected ? "text-light" : "text-white"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                if (!isNoneSelected) handleClearAll();
              }}
              disabled={isNoneSelected}
            >
              Clear
            </button>
          </li>
        )}
        {items.map((item, index) => (
          <li key={index}>
            <label
              className="dropdown-item text-white d-flex align-items-center justify-content-between"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="d-flex align-items-center flex-grow-1">
                <input
                  type={input_type}
                  className="form-check-input me-2"
                  checked={selectedItems.includes(item)}
                  onChange={() => handleChange(item)}
                  disabled={isDisabled(item)}
                />
                <span className="me-2">
                  {item === "PDF" ? "PDF" : renderCustomLabel(item)}
                </span>
              </div>
              {counts && totalCounts && (
                <span className="ms-auto">
                  ({counts[item] || 0}/{totalCounts[item] || 0})
                </span>
              )}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
